<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        연수원 이용 신청
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="educourse-view-container course-cancel">
        <div class="view-contents">
          <section class="content-section">
            <div class="section-content">
              <div class="content-item bd-clear">
                <div class="title">연수원</div>
                <div class="desc">
                  <p>{{ trnctNm }}</p>
                </div>
              </div>
              <div class="content-item">
                <div class="title">투숙기간</div>
                <div class="desc">
                  <p>{{ rsvtAplyDd + " ~ " + usePeriodTxt2 }}</p>
                </div>
              </div>
              <div class="content-item">
                <div class="title">인원</div>
                <div class="desc">
                  <p>성인 {{ aplyPeopl }}명</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="saveTrnctAply">예약</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
  <div class="kb-alert-container" :class="{'is-active' : completeAlert}">
    <div class="kb-alert">
      <div class="kb-alert-contents">
        <strong class="kb-alert-title">신청완료</strong>
        <p class="kb-alert-text">연수원신청이 완료되었습니다.</p>
      </div>
      <div class="kb-alert-actions">
        <button class="kb-btn-alert" @click="completeApply"><span class="text">확인</span></button>
        <button class="kb-btn-alert" @click="goApplyHistory"><span class="text">신청내역</span></button>
      </div>
    </div>
  </div>

</template>
<script>
import {computed, onMounted, ref} from 'vue';
import {useStore} from "vuex";
import {isSuccess, timestampToDateFormat} from "@/assets/js/util";

import {ACT_INSERT_TRNCT_APLY} from "@/store/modules/trnct/trnct";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileApplyStep3',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage,showConfirm} = useAlert();


    const session = computed(() => store.state.auth.session);
    const rsvtAplyDd = computed(() => store.state.trnct.mobileApplyInfo.rsvtAplyDd);
    const trnctNm = computed(() => store.state.trnct.mobileApplyInfo.trnctNm);
    const aplyPeopl = computed(() => store.state.trnct.mobileApplyInfo.aplyPeopl);
    const usePerid = computed(() => store.state.trnct.mobileApplyInfo.usePerid);
    const usePeriodTxt = computed(() => store.state.trnct.mobileApplyInfo.usePeriodTxt);
    const usePeriodTxt2 = computed(() => store.state.trnct.mobileApplyInfo.usePeriodTxt2);
    const completeAlert = ref(false);

    onMounted(()=> {

    })

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const saveTrnctAply = async () => {
      showConfirm({
          text: '예약 신청 하시겠습니까?',
          callback: async () => {               
            await store.dispatch(`trnct/${ACT_INSERT_TRNCT_APLY}`, store.state.trnct.mobileApplyInfo).then(res => {
              console.log(isSuccess(res))
              if (isSuccess(res)) {
                console.log(isSuccess(res))
                completeAlert.value=true;
              }else {
                showMessage('연수원 신청이 불가능 합니다.');
              }
            }).catch((e) => {
              console.error(e);
            })
          }
        });
    }

    const completeApply = () => {
      completeAlert.value = false;
      router.push('/daily/apply');
    }

    const goApplyHistory = () => {
      completeAlert.value = false;
      router.push('/daily/history');
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      session,
      getDateFormat,
      saveTrnctAply,
      usePerid,
      usePeriodTxt,
      trnctNm,
      aplyPeopl,
      rsvtAplyDd,
      usePeriodTxt2,
      completeAlert,
      completeApply,
      goApplyHistory,
      goBack
    };
  },
};
</script>
